import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Contract } from "ethers";
import { toast } from "react-toastify";
import { useAccount, useContractReads, useSigner } from "wagmi";
import { addressConcat } from "../../utils/address_utils";
import { fetchUserTokens } from "../api/api";
import { abi } from "../constants/contractAbi";

export interface IEthereumContextValues {
  contractAddress: string;
  contractData: any;
  contractSigner?: Contract;
  chainId: number;
  userTokens: any[];
}

export enum ContractFunctions {
  tokenCounter,
  URI,
}

export const EthereumContext = createContext({} as IEthereumContextValues);

export const useEthereum = () => {
  return useContext(EthereumContext);
};

export const EthereumProvider = ({
  children,
  dev,
}: {
  children: React.ReactNode;
  dev: "dev" | "goerli" | "mainnet";
}): JSX.Element => {
  const { ethereum } = window;
  const { address } = useAccount();
  const [userTokens, setUserTokens] = useState([]);
  const { data: signer } = useSigner();

  const chainId: number = dev === "dev" ? 1337 : dev === "mainnet" ? 1 : 5;

  const goerliAddress = "0xA0dD5eD4B5AFdc98a2a523E6c7aA86342E0A770F";
  const mainnnetAddress = "0xf5D8A9C6E5235e4d02B0646dadFF07dAB56daD23";
  const devAddress = "";

  const contractAddress =
    dev == "dev"
      ? devAddress
      : dev === "goerli"
      ? goerliAddress
      : mainnnetAddress;

  useEffect(() => {
    const handleAccountChanged = (accounts: any) => {
      if (accounts) {
        console.log("changed acccount", accounts[0]);
        toast(`Changed Account: \n ${addressConcat(accounts[0])}`, {
          style: { color: "#7F3B2F" },
        });
      }
    };

    ethereum?.on("accountsChanged", handleAccountChanged);

    return () => {
      ethereum?.removeListener("accountsChanged", handleAccountChanged);
    };
  });

  const contract = {
    addressOrName: contractAddress,
    contractInterface: abi,
    chainId,
  };

  const contractRead = useContractReads({
    contracts: [
      {
        ...contract,
        functionName: "tokenCounter",
        chainId,
      },
      {
        ...contract,
        functionName: "uri",
        chainId,
        args: [1],
      },
    ],
    watch: true,
  });

  const contractData = contractRead.data;

  const contractSigner = useMemo(() => {
    if (signer) return new Contract(contractAddress, abi, signer);
  }, [signer]);

  // console.log(contractData);
  // console.log({ address });

  useEffect(() => {
    if (!address) return setUserTokens([]);

    const fetchData = async () => {
      //
      const tokens = await fetchUserTokens(address);

      // console.log(tokens);
      setUserTokens(tokens);
    };

    fetchData();
  }, [address]);

  return (
    <EthereumContext.Provider
      value={{
        contractAddress,
        contractData,
        userTokens,
        contractSigner,
        chainId,
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};
