import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import { ToastContainer } from "react-toastify";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { EthereumProvider } from "./common/contexts/ethereum";

const { chains, provider } = configureChains(
  [
    chain.mainnet,
    // chain.goerli,
    // chain.localhost,
  ],
  [
    jsonRpcProvider({
      rpc: (chain) =>
        chain.id === 1337
          ? { http: "http://localhost:8545" }
          : chain.id === 1
          ? {
              http: "https://cold-wispy-firefly.quiknode.pro/efe16c65e7bdd497c2a34cdef175db8385bed823/",
            }
          : {
              http: "https://alien-long-silence.ethereum-goerli.quiknode.pro/a798f49722b8ac00cbc9aabdca5e7c35f39d3ff0/",
              webSocket:
                "wss://alien-long-silence.ethereum-goerli.quiknode.pro/a798f49722b8ac00cbc9aabdca5e7c35f39d3ff0/",
            },
    }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Deep Fake Dapp",
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        coolMode
        showRecentTransactions={true}
      >
        <EthereumProvider dev={"mainnet"}>
          <App />
          <ToastContainer position="bottom-right" />
        </EthereumProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
