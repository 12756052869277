import { ITokenProps } from "../../types/data";

export const fetchUserTokens = async (address: string) => {
  const res = await fetch(
    "https://api.thegraph.com/subgraphs/name/seidprojects/deepfake",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `{ accounts(where:{id:"${address.toLowerCase()}"}) { balances { token { identifier } } } }`,
        variables: null,
      }),
    }
  ).then((res) => res.json());

  // console.log({ res });
  const tokens = res.data?.accounts?.[0]?.balances?.map(
    (token: any) => token.token.identifier
  );
  return tokens || [];
};

export const fetchTokens = async (
  uri: string,
  setTokens: React.Dispatch<React.SetStateAction<ITokenProps[]>>,
  controller: AbortController
): Promise<any> => {
  try {
    const res = await fetch(
      "https://api.thegraph.com/subgraphs/name/seidprojects/deepfake",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: "{deepFakeTokens {id price mintState approved URI } }",
          variables: null,
        }),
        signal: controller.signal,
      }
    ).then((res) => res.json());

    // console.log(res.data.deepFakeTokens);
    const tokens = res.data.deepFakeTokens;

    const promises: Promise<any>[] = [];

    tokens.forEach((token: any) => {
      //

      promises.push(
        fetch(uri.replace("{id}", (token.id as string).padStart(64, "0"))).then(
          (res) => res.json()
        )
      );
    });

    const tokenData = await Promise.all(promises);
    // console.log({ tokenData });
    setTokens(
      tokenData.map((token, id) => ({ ...token, id: (id + 1).toString() }))
    );
  } catch (e) {
    console.log(`error: ${e}`);
  }
};

export const fetchToken = async (
  uri: string,
  token: string,
  setToken: React.Dispatch<React.SetStateAction<ITokenProps>>,
  controller: AbortController
) => {
  try {
    const res = await fetch(uri.replace("{id}", token.padStart(64, "0")), {
      signal: controller.signal,
    }).then((res) => res.json());

    // console.log({ res });
    if (res?.image) setToken(res);
  } catch (e) {
    console.log(`error: ${e}`);
  }
};
