import "./MintPage.css";
import { MintToken } from "./MintToken";
import { useEffect, useState } from "react";
import { BigNumber } from "ethers";
import { MintNavbar } from "../../components/Navbar/MintNavbar";

interface IToken {
  approved: boolean;
  id: number;
  mintState: BigNumber;
  price: BigNumber;
}
const MintPage = () => {
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [noTokens, setNoTokens] = useState<Boolean>(false);

  const getData = async (controller: AbortController) => {
    const response = await fetch(
      "https://api.thegraph.com/subgraphs/name/seidprojects/deepfake",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query:
            "{deepFakeTokens(where:{mintState: 1}) {id price mintState approved}}",
          variables: null,
        }),
        signal: controller.signal,
      }
    );
    const data = await response.json();
    // console.log(data);
    const tokens = data?.data?.deepFakeTokens;
    setTokens(tokens);
    tokens?.length ? setNoTokens(false) : setNoTokens(true);
  };

  useEffect(() => {
    const controller = new AbortController();
    getData(controller);

    return () => controller.abort();
  }, []);

  return (
    <div className="page-container px-10 md:px-0">
      <MintNavbar />
      {noTokens ? (
        <div className="flex items-center flex-1">Fake Tokens Unavailable</div>
      ) : (
        <>
          <div className="header">Claim your Fake NFT Today!</div>
          <div className="nft-pay-token-container justify-start my-8">
            {tokens.map((value) => {
              return <MintToken id={value.id} key={value.id} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default MintPage;
