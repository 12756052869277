import Navbar from "../../components/Navbar/Navbar";
import animation from "../../../assets/images/main_animation.mp4";
import { useRef, useEffect, useState } from "react";

export const LandingPage = () => {
  return (
    <div className="max-w-7xl px-10 pb-4">
      <div className="border-b-solid border-b-2 border-b-gray-500 pb-4">
        <Navbar />
        <Video />
        <p className="w-full text-base text-left font-bold ">
          The Fakes have opened a WEB3 Portal into the Fakeside.
        </p>
        <p className="w-full text-base text-left font-bold ">
          Presenting limited DEEPFAKE NFT collections, with each token acting as
          your Fake membership.
        </p>
        <p className="w-full text-base text-left font-bold ">
          The Fake Fam United Club is Open.
        </p>
      </div>
    </div>
  );
};

const Video = () => {
  const vidRef: React.LegacyRef<HTMLVideoElement> = useRef(null);
  const [isEnded, setIsEnded] = useState(false);

  const handleOnEnded = () => {
    if (vidRef.current) {
      vidRef.current.currentTime = 0;
      setIsEnded(true);
    }
  };

  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.playbackRate = 2;
    }
  }, []);

  return (
    <>
      <div className="relative mb-5">
        <video
          ref={vidRef}
          autoPlay
          muted
          onEnded={handleOnEnded}
          className={`${isEnded ? "md:hidden" : ""}`}
        >
          <source src={animation} type="video/mp4" />
        </video>
        {isEnded && (
          <div className="absolute md:relative bg-black bottom-0 left-0 right-0 w-72 mx-auto mb-8 rounded text-2xl px-8 py-4 font-bold">
            WELCOME TO THE FAKE FAM UNITED CLUB
            <a
              className="bg-white text-black w-full rounded-lg no-underline block"
              href="/gallery"
            >
              ENTER
            </a>
          </div>
        )}
      </div>
    </>
  );
};
