import MintPage from "./common/pages/MintPage/MintPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./common/components/Footer/Footer";
import { Gallery } from "./common/pages/Gallery/Gallery";
import { LandingPage } from "./common/pages/LandingPage/LandingPage";
import { GalleryItem } from "./common/pages/GalleryItem/GalleryItem";

function App() {
  // const { chain } = useNetwork();

  // const { openConnectModal } = useConnectModal();
  return (
    <BrowserRouter>
      <div className="App">
        <div className="app-container">
          <Routes>
            {/* <Navbar /> */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/mint" element={<MintPage />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallery/:id" element={<GalleryItem />} />
            {/* <AccountBar /> */}
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
