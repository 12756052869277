import Logo from "../../../assets/images/deepfakeLogo.png";
import {
  RiInstagramLine,
  RiSoundcloudLine,
  RiTwitterFill,
  RiYoutubeFill,
} from "react-icons/ri";
import { useAccount, useNetwork } from "wagmi";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useEthereum } from "../../contexts/ethereum";
import { addressConcat } from "../../../utils/address_utils";

const GalleryNavbar = () => {
  const { isConnected, address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();
  const { chainId } = useEthereum();
  const { chain } = useNetwork();

  return (
    <>
      <nav className="navbar select-none justify-between my-8 md:h-fit md:gap-5 items-center">
        <div className="text-base italic font-bold min-w-1/3 text-start md:w-full md:text-center md:h-10 flex gap-5 md:justify-center">
          <a href="/mint">Mint</a>
          <a href="/gallery">Gallery</a>
        </div>
        <div className="h-full min-w-1/3 flex justify-center md:w-full">
          <a href="/gallery">
            <img src={Logo} className="h-24" />
          </a>
        </div>
        <div className="min-w-1/3 md:w-full ">
          <div className="w-full text-right md:text-center text-sm">
            {isConnected && chain?.id === chainId ? (
              <div>
                Wallet:{" "}
                <span className="italic">{addressConcat(address!)}</span>
              </div>
            ) : (
              <button
                className="text-sm bg-white text-black w-fit p-2 rounded-lg font-bold"
                onClick={!isConnected ? openConnectModal : openChainModal}
              >
                Connect Wallet
              </button>
            )}
          </div>
          <div className="w-full flex gap-5 justify-end md:justify-center md:h-10">
            <a
              href="https://instagram.com/deepfakeofficial"
              target={"_blank"}
              rel="noreferrer"
            >
              <RiInstagramLine className="w-6" />
            </a>
            <a
              href="https://twitter.com/deepfakemusic?lang=en"
              target={"_blank"}
              rel="noreferrer"
            >
              <RiTwitterFill className="w-6" />
            </a>
            <a
              href="https://soundcloud.com/deepfakemusic"
              target={"_blank"}
              rel="noreferrer"
            >
              <RiSoundcloudLine className="w-6" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCCmA5vxNxJJOze3U_hm2mMA"
              target={"_blank"}
              rel="noreferrer"
            >
              <RiYoutubeFill className="w-6" />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default GalleryNavbar;
