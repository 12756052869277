import { useState } from "react";
import { useAccount, useNetwork } from "wagmi";
import { useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useEthereum } from "../../contexts/ethereum";
import { FaSpinner } from "react-icons/fa";
import { ethers } from "ethers";
import { toast } from "react-toastify";

export const MintToken = ({ id }: { id: number }) => {
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();
  const { chainId, contractSigner } = useEthereum();
  const { chain } = useNetwork();
  const [minting, setMinting] = useState(false);
  const [showIFrame, setShowIFrame] = useState(false);

  const handleMint = async () => {
    console.log("minting");
    setMinting(true);
    try {
      const value = ethers.utils.parseUnits("1", "wei");
      const receipt = await contractSigner?.mint(1, { value });
      await receipt.wait();
      setMinting(false);
    } catch (e: any) {
      console.log({ e });
      setMinting(false);
      toast.error(e?.data?.data?.reason || "Transaction Failed");
    }
  };

  return (
    <div className="static flex flex-col bg-zinc-500 px-5 py-8 rounded-md">
      Token {id}
      {showIFrame && (
        <div
          className="fixed cursor-pointer top-0 left-0 m-auto flex flex-col justify-center text-center w-full h-full bg-modal"
          onClick={() => setShowIFrame(false)}
        >
          <iframe
            className="border-none z-50 m-auto flex justify-center"
            height="550"
            src={`https://payments.nftpay.xyz/iframe/iframe_pay/09922a34-fb16-4f2a-be6f-29fd88accaf6?&_tokenId=${id}`}
          ></iframe>
        </div>
      )}
      <button className="mint-button" onClick={() => setShowIFrame(true)}>
        Mint With NFT Pay
      </button>
      {isConnected && chain?.id === chainId ? (
        <button className="mint-button" onClick={handleMint} disabled={minting}>
          {minting ? (
            <>
              <FaSpinner className="icon-spin" /> Minting
            </>
          ) : (
            <>Mint</>
          )}
        </button>
      ) : (
        <button
          className="mint-button"
          onClick={!isConnected ? openConnectModal : openChainModal}
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
};
