import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { ITokenProps } from "../../../types/data";
import { fetchTokens } from "../../api/api";
import GalleryNavbar from "../../components/Navbar/GalleryNavbar";
import { useEthereum, ContractFunctions } from "../../contexts/ethereum";

export const Gallery = () => {
  const [tokens, setTokens] = useState<ITokenProps[]>([]);
  const { contractData, userTokens } = useEthereum();

  const { isConnected } = useAccount();

  useEffect(() => {
    const controller = new AbortController();
    if (!contractData?.[ContractFunctions.URI]) return;
    fetchTokens(contractData[ContractFunctions.URI], setTokens, controller);

    return () => {
      controller.abort();
    };
  }, [contractData?.[ContractFunctions.URI], isConnected]);

  // console.log({ tokens, userTokens });
  return (
    <div className="max-w-7xl w-full px-10 pb-4">
      <GalleryNavbar />
      <div className=" py-2 sm:px-6 max-w-7xl">
        <h2 className="font-bold text-gray-200 text-4xl text-left">Gallery</h2>
        <div className="select-none w-7xl mt-8 grid grid-cols-4 gap-y-12 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 md:gap-x-6  gap-x-8">
          {tokens.map((product) => {
            const blurCard = !userTokens.includes(product.id);
            // console.log(blurCard, userTokens, product.id);
            return (
              <GalleryToken {...product} key={product.id} blurCard={blurCard} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface IGalleryToken {
  id: string;
  href?: string;
  image: string;
  imageAlt?: string;
  name: string;
  blurCard?: boolean;
}
const GalleryToken = (token: IGalleryToken) => {
  return (
    <div key={token.id}>
      <a href={token.blurCard ? undefined : "/gallery/" + token.id}>
        <div className="relative h-fit min-w-40 w-full">
          <div
            className={`relative  w-full overflow-hidden rounded-lg grayscale ${
              token.blurCard ? "blur-sm" : ""
            }`}
          >
            <video
              // alt={token?.imageAlt || "Deep Fake Token"}
              className="pointer-events-none w-full aspect-square object-contain object-center"
            >
              <source src={token.image} />
            </video>
          </div>
          <div className="relative mt-2">
            <h3 className="text-lg text-gray-200 text-left font-bold">
              {token.name}
            </h3>
          </div>
        </div>
      </a>
    </div>
  );
};
