import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { ITokenProps } from "../../../types/data";
import { fetchToken } from "../../api/api";
import GalleryNavbar from "../../components/Navbar/GalleryNavbar";
import { ContractFunctions, useEthereum } from "../../contexts/ethereum";

export const GalleryItem = () => {
  const params = useParams();
  const { userTokens, contractData, contractAddress } = useEthereum();
  const { isConnected } = useAccount();
  const [showVid, setShowVid] = useState(false);
  const [token, setToken] = useState<ITokenProps>({} as ITokenProps);

  // console.log(products[Number(params.id)]);

  const products = [
    {
      id: 0,
      name: "DF WRLD CHI",
      subtitle: "September 03, 2022 - Fake Fam Meetup Chicago",
      imageAlt: "",
      href: "/gallery/0",
      medium: "Video (MP4)",
      fileSize: "122 MB",
      dimensions: "2160x2700",
    },
    {
      id: 1,
      name: "DF WRLD PARADISE",
      imageAlt: "",
      href: "/gallery/1",
      medium: "video (MP4)",
      fileSize: "122 MB",
      dimensions: "2160x2700",
    },
  ];

  const product = products[0];

  // console.log({ params });
  useEffect(() => {
    const controller = new AbortController();
    if (params.id && contractData?.[ContractFunctions.URI])
      fetchToken(
        contractData[ContractFunctions.URI],
        params.id,
        setToken,
        controller
      );

    return () => {
      controller.abort();
    };
  }, [contractData?.[ContractFunctions.URI], isConnected]);

  // console.log({ userTokens, token });

  return (
    <div className="max-w-7xl w-full px-10 pb-10 items-center">
      <GalleryNavbar />
      {userTokens?.includes(params.id) ||
        (1 && (
          <div className="max-w-3xl w-full mx-auto">
            <video
              className={`h-96 mx-auto ${
                token.image ? "hover:cursor-pointer" : ""
              }`}
              onClick={() => {
                token.image && setShowVid(true);
              }}
            >
              <source src={token.image} />
            </video>
            {token.image && (
              <p className="text-sm mb-10 italic"> CLICK TO PLAY!</p>
            )}
            <p className="text-center font-bold text-3xl">{token.name}</p>
            <p className="text-center font-bold text-xl">{token?.subtitle}</p>
            <div className="mt-10 text-left text-base text-gray-500">
              <div className="text-center font-bold">DETAILS</div>
              <div className="mt-5">
                <div className="flex flex-wrap gap-10 justify-center">
                  <div className="">
                    <div className="w-36  inline-block">Medium</div>
                    <div className="text-white w-32 inline-block">
                      {token.medium || "..."}
                    </div>
                  </div>
                  <div className="">
                    <div className="w-36  inline-block">Contract Address</div>
                    <a
                      className={`text-white w-32 inline-block ${
                        contractAddress ? "underline" : ""
                      }`}
                      href={`https://etherscan.io/address/${contractAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {addressConcat(contractAddress)}
                    </a>
                  </div>
                  <div></div>
                </div>
                <div className="flex flex-wrap gap-10 justify-center">
                  <div className="">
                    <div className="w-36  inline-block">Dimensions</div>
                    <div className="text-white w-32 inline-block">
                      {token.dimensions || "..."}
                    </div>
                  </div>
                  <div className=" ">
                    <div className="w-36  inline-block">Token Standard</div>
                    <div className="text-white w-32 inline-block">
                      DFRWRLD-333
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="flex flex-wrap gap-10 justify-center">
                  <div className="">
                    <div className="w-36  inline-block">File Size</div>
                    <div className="text-white w-32 inline-block">
                      {token.file_size || "..."}
                    </div>
                  </div>
                  <div className=" ">
                    <div className="w-36  inline-block">Blockchain</div>
                    <div className="text-white w-32 inline-block">Ethereum</div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {showVid && <Video src={token.image} setShowVid={setShowVid} />}
    </div>
  );
};

const Video = ({
  src,
  setShowVid,
}: {
  src: string;
  setShowVid: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const vidRef: React.LegacyRef<HTMLVideoElement> = useRef(null);
  const [volume, setVolume] = useState(0.15);

  useEffect(() => {
    if (vidRef.current) {
      // console.log(vidRef.current);
      vidRef.current.play();
      vidRef.current.volume = volume;
    }
  }, []);

  const handleClick = () => {
    setShowVid(false);
  };

  const handleVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (vidRef.current) {
      const volumeValue = Number(e.target.value);
      vidRef.current.volume = volumeValue;
      setVolume(volumeValue);
    }
  };
  return (
    <div
      className="fixed m-auto bg-modal top-0 bottom-0 left-0 right-0 flex justify-center items-center"
      onClick={() => handleClick()}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <video
          className="w-96 aspect-square cursor-pointer"
          ref={vidRef}
          onClick={() => {
            vidRef.current?.paused
              ? vidRef.current.play()
              : vidRef.current?.pause();
          }}
        >
          <source src={src} />
        </video>
        <div className="flex justify-center items-center">
          <span className="text-base mr-2">volume</span>
          <input
            type={"range"}
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={(e) => handleVolume(e)}
          />
        </div>
      </div>
    </div>
  );
};

function addressConcat(addr: string): string {
  const l = addr.length,
    n = 4;
  return addr.substring(0, n) + "..." + addr.substring(l - n, l);
}
