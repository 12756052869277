import "./footer.css";
import { ReactComponent as AtlasLogo } from "../../../assets/images/atlasIcon.svg";
import Logo from "../../../assets/images/deepfakeLogo.png";
import footerimg from "../../../assets/images/footer.jpg";
import { MdArrowRightAlt } from "react-icons/md";
import { useState } from "react";

const Footer = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) return;
    const res = await fetch(
      "https://us1.api.mailchimp.com/3.0/16d1efae6581054b6b51c0447c79c356-us1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(email);
  };

  return (
    <>
      <nav className="footer min-h-100 flex-wrap items-center">
        <form
          className="min-w-1/3 text-sm lg:w-full p-4"
          onSubmit={handleSubmit}
        >
          <div className="w-fit m-auto">
            <p className="text-left">JOIN THE FAKE LIST</p>
            <div className="flex justify-center">
              <input
                className="bg-gray-800 placeholder:italic px-3 py-1 h-7 focus-visible:outline-none"
                placeholder="Email Address"
                type={"email"}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <button className="bg-gray-600 h-7 w-7">
                <MdArrowRightAlt className="mx-auto" />
              </button>
            </div>
          </div>
        </form>
        <a
          className="flex justify-center h-16 min-w-1/3 lg:w-full"
          href="/gallery"
        >
          <img src={Logo} alt="Deepfake Logo" className="h-full" />
        </a>
        <a
          className="logo-container min-w-1/3 lg:w-full flex text flex-wrap justify-center items-center gap-2 p-4"
          href="https://www.atlascorp.io/"
          target={"_blank"}
          rel="noreferrer"
        >
          <div className="text-2xl">Developed by</div>
          <AtlasLogo className="atlas-icon h-10 flex flex-wrap justify-center max-w-fit fill-white" />
          {/* <Name className="atlas-name" /> */}
        </a>
      </nav>
      <div className="">
        <p className="text-sm">© 2022 DEEPFAKE, All Rights Reserved</p>
        <img src={footerimg} className={"w-full"} />
      </div>
    </>
  );
};

export default Footer;
